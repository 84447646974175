<template>
  <div class="page-bgf6">
    <div class="wrapper-content">
      <div class="partners-title">
        <h2>{{ title }}会员申请</h2>
      </div>
      <div class="partners-content" v-if="applyVisiable">
        <el-form :model="applyForm" :rules="applyRules" ref="ruleForm" label-width="100px" class="demo-ruleForm apply-form" label-position="left">
          <el-form-item label="姓名" prop="realName" v-if="uType == 1">
            <el-input v-model="applyForm.realName" placeholder="输入姓名" />
          </el-form-item>
          <el-form-item label="身份证" prop="id" v-if="uType == 1">
            <el-input v-model="applyForm.id" maxlength="18" placeholder="输入身份证" @change="getGender(applyForm.id)"/>
          </el-form-item>
          <el-form-item label="性别" prop="sex" v-if="uType == 1">
            <el-radio-group v-model="applyForm.sex">
              <el-radio label="1">男</el-radio>
              <el-radio label="2">女</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="联系方式" prop="phone" v-if="uType == 1">
            <el-input v-model="applyForm.phone" placeholder="输入联系方式" maxlength="11"/>
          </el-form-item>
          <el-form-item label="头像" prop="photo" v-if="uType == 1">
            <el-upload
                class="avatar-uploader"
                :action="uploadImg()"
                :headers="uploadHeaders()"
                :show-file-list="false"
                accept=".jpg,.png,jpeg"
                :on-success="handleSuccessPhoto">
              <img v-if="applyForm.photo" :src="loadUrl(applyForm.photo)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="单位名称" prop="fullName" v-if="uType == 2">
            <el-input v-model="applyForm.fullName" placeholder="输入单位名称" />
          </el-form-item>
          <el-form-item label="法人名称" prop="corporateName" v-if="uType == 2">
            <el-input v-model="applyForm.corporateName" placeholder="输入法人名称" />
          </el-form-item>
          <el-form-item label="联系人" prop="link" v-if="uType == 2">
            <el-input v-model="applyForm.link" placeholder="输入联系人" />
          </el-form-item>
          <el-form-item label="联系方式" prop="linkPhone" v-if="uType == 2">
            <el-input v-model="applyForm.linkPhone" placeholder="输入联系方式" maxlength="11"/>
          </el-form-item>
          <el-form-item label="单位简介" prop="introduction" v-if="uType == 2">
            <el-input v-model="applyForm.introduction" placeholder="输入单位简介"/>
          </el-form-item>
          <el-form-item label="地址" prop="address" v-if="uType == 2">
            <el-input v-model="applyForm.address" placeholder="输入地址" />
          </el-form-item>
          <el-form-item label="会员类型" prop="configId">
            <el-select v-model="applyForm.configId" placeholder="请选择" @change="configChange">
              <el-option v-for="(item,index) in configData" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-for="(item,index) in customForms" :key="index" :label="item.label" :prop="item.vModel">
            <template v-if="item.tagIcon === 'radio'">
              <el-radio-group v-model="applyForm[item.vModel]" :placeholder="item.placeholder">
                <el-radio v-for="iRadio in item.options" :key="iRadio.value" :value="iRadio.value" :label="iRadio.label"></el-radio>
              </el-radio-group>
            </template>
            <template v-if="item.tagIcon === 'select'">
              <el-select :class="{ noValid: !item.required }" v-model="applyForm[item.vModel]" :placeholder="item.placeholder">
                <el-option v-for="iSelect in item.options" :key="iSelect.name" :name="iSelect.name" :value="iSelect.name" :label="iSelect.value"></el-option>
              </el-select>
            </template>
            <template v-if="item.tagIcon === 'input'">
              <el-input :class="{ noValid: !item.required }" v-model="applyForm[item.vModel]" type="text" :label="item.label"
                        :maxlength="item.maxlength" :readonly="item.readonly" :placeholder="item.placeholder"
                        :rules="[ { required: item.required, message: item.placeholder },
                          { pattern: [item.regList].pattern, message: [item.regList].message, },
                        ]">
              </el-input>
            </template>
            <template v-if="item.tagIcon === 'textarea'">
              <el-input :class="{ noValid: !item.required }" v-model="applyForm[item.vModel]" type="textarea" :label="item.label"
                        :maxlength="item.maxlength" :readonly="item.readonly" :placeholder="item.placeholder"
                        :rules="[
                          { required: item.required, message: item.placeholder },
                          { pattern: [item.regList].pattern, message: [item.regList].message, },
                        ]">
              </el-input>
            </template>
            <template v-if="item.tagIcon === 'upload'">
              <el-upload :class="{ noValid: !item.required }" :action="item['list-type'] === 'text' ? uploadFiles() : uploadImg()"
                         :headers="uploadHeaders()"
                         :on-success=" (res, file, fileList) => { return uploadSuccess(res, file, fileList, item.vModel); }"
                         :on-remove=" (file, fileList) => { return uploadRemove(file, fileList, item.vModel); }"
                         :multiple="item.multiple"
                         :file-list="fileList"
                         :placeholder="'请上传'+item.label"
                         style="width: 600px;"
                >
                <el-button size="small" type="primary">{{ item.buttonText }}</el-button>
                <div slot="tip" class="el-upload__tip" v-if="item.showTip">只能上传不超过 {{item.fileSize }}{{ item.sizeUnit }} 的{{item.accept}}文件</div>
              </el-upload>
            </template>
          </el-form-item>

          <!-- 等级 -->
<!--          <el-form-item v-if="levelshow" key="levelvalue" label="会员等级" rop="level">-->
<!--            <el-select v-model="applyForm.level" placeholder="请选择" @change="getConfigs" :disabled="type === 'fix'">-->
<!--              <el-option v-for="(lItem, index) in leveloptions" :key="index" :label="lItem.memberLevel" :value="lItem.memberLevel"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->

<!--          <el-form-item v-if="config.cardOfflineFee" label="是否需要实体证" prop="hascard">-->
<!--            <el-radio-group v-model="applyForm.hascard">-->
<!--              <el-radio :label="0">否</el-radio>-->
<!--              <el-radio :label="1">是</el-radio>-->
<!--            </el-radio-group>-->
<!--          </el-form-item>-->
        </el-form>

        <!--  费用，期限 -->
        <ul class="partners-info priceInfo" v-if="formData.configId">
          <li class="item">
            <span class="name">会员费：</span>
            <span class="value danger">￥{{ config.price }}</span>
          </li>
          <li v-if="config.validityDuration" class="item">
            <span class="name">会员有效时长：</span>
            <span class="value">{{ config.validityDuration }}个月</span>
          </li>
          <li v-else class="item">
            <span class="name">会员有效时间段：</span>
            <span class="value">{{toDate(config.validityStartTime, "yyyy-MM-dd")}} 至 {{toDate(config.validityEndTime, "yyyy-MM-dd")}}</span>
          </li>
        </ul>
        <ul class="partners-info priceInfo" v-if="type == 'pay'||type==undefined">
          <el-radio-group class="pay-type-group" v-model="formData.payModel">
            <el-radio :label="1">
              <span class="iconfont icon-weixinzhifu" :class="{ checked: formData.payModel == 1 }"></span>线上支付
            </el-radio>
            <el-radio :label="2" class="offline-radio">
              <div class="off-line">
                <span class="iconfont icon-yinhangqia" :class="{ checked: formData.payModel == 2 }"></span>
                <span>线下支付（线下支付需上传支付凭证审核）</span>
              </div>
            </el-radio>
          </el-radio-group>
          <div>
            <el-upload
                v-if="formData.payModel == 2"
                class="avatar-uploader"
                :action="uploadImg()"
                :headers="uploadHeaders()"
                :show-file-list="false"
                accept=".jpg,.png,jpeg"
                :on-success="handleSuccess">
              <img v-if="formData.offlineVoucher" :src="loadUrl(formData.offlineVoucher)" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </ul>
        <div class="partners-btn">
          <div class="checkbox-bar">
            <el-checkbox v-model="checked">
              <span class="checkbox-content">本人自愿成为“{{title}}”会员，遵守该社会团体章程和各项规章制度，履行该社会团体赋予的权利和义务。</span>
            </el-checkbox>
          </div>
          <div class="btn-bar">
            <el-button v-if="itemId" type="primary" @click="updateForm('ruleForm')" :disabled="!checked">修改信息</el-button>
            <el-button v-else type="primary" @click="submitForm('ruleForm')" :disabled="!checked" :loading="btnLoading">成为会员</el-button>
            <el-button @click="resetForm('ruleForm')">取消</el-button>
          </div>
        </div>
      </div>

      <div class="partners-content" v-else>
        <div class="partners-detail">
          <ul class="partners-info non-border" v-if="uType == 1">
            <li class="title">申请信息</li>
            <li class="item" v-if="uType == 1">
              <span class="name">姓名：</span>
              <span class="value">{{ applyForm.realName }}</span>
            </li>
            <li class="item" v-if="uType == 1">
              <span class="name">身份证：</span>
              <span class="value">{{ applyForm.id }}</span>
            </li>
            <li class="item" v-if="uType == 1">
              <span class="name">性别：</span>
              <span class="value">{{ applyForm.sex == 1 ? "男" : "女" }}</span>
            </li>
            <li class="item" v-if="uType == 1">
              <span class="name">联系方式：</span>
              <span class="value">{{ applyForm.phone }}</span>
            </li>
          </ul>
          <ul class="partners-info non-border" v-if="uType == 2">
            <li class="title">申请信息</li>
            <li class="item" v-if="uType == 2">
              <span class="name">法人名称：</span>
              <span class="value">{{ applyForm.corporateName }}</span>
            </li>
            <li class="item" v-if="uType == 2">
              <span class="name">联系人：</span>
              <span class="value">{{ applyForm.link }}</span>
            </li>
            <li class="item" v-if="uType == 2">
              <span class="name">联系电话：</span>
              <span class="value">{{ applyForm.linkPhone }}</span>
            </li>
            <li class="item" v-if="uType == 2">
              <span class="name">简介：</span>
              <span class="value">{{ applyForm.introduction }}</span>
            </li>
            <li class="item" v-if="uType == 2">
              <span class="name">地址：</span>
              <span class="value">{{ applyForm.address }}</span>
            </li>
          </ul>

          <ul class="partners-info non-border non-paddingTop">
            <li v-for="(item, index) in detail" :key="index" class="item">
              <span class="name">{{ item.name }}：</span>
              <span class="value" v-if="item.type !== 'upload'">{{item.value}}</span>
              <el-image v-else style="width: 300px; height: 200px" :src="item.value[0]" :preview-src-list="item.value"></el-image>
            </li>
            <li v-if="config.dueTime" class="item">
              <span class="name">会员有效时长：</span>
              <span class="value">{{ config.dueTime }}个月</span>
            </li>
            <li v-else class="item">
              <span class="name">会员有效时间段：</span>
              <span class="value">{{ toDate(config.startDate, "yyyy-MM-dd") }} 至 {{ toDate(config.endDate, "yyyy-MM-dd") }}</span>
            </li>
          </ul>

          <ul class="partners-info">
            <li class="title">支付金额</li>
            <li class="item" v-if="applyForm.hascard == 1">
              <span class="name danger">会员费：</span>
              <span class="value danger">￥{{ config.memberPrice }}</span>
            </li>
            <li class="item" v-if="applyForm.hascard == 1">
              <span class="name danger">实体会员证费用：</span>
              <span class="value danger">￥{{ config.cardOfflineFee }}</span>
            </li>
            <li class="item">
              <span class="name danger">应付金额：</span>
              <span v-if="applyForm.hascard == 0" class="value danger" style="font-weight: bold" >￥{{ config.memberPrice }}</span>
              <span v-else class="value danger" style="font-weight: bold" >￥{{ totalPrice }}</span>
            </li>
          </ul>
        </div>
      </div>
      <Pay v-if="payVisible" ref="Pay" />
    </div>
  </div>
</template>

<script>
import {
  getFullName,
  memberBuildFormGetTemplate,
  memberConfigGetList,
  memberApplyConfirmApply,
  memberApplyConfirmApplyUpdate,
  memberApplyInfo,
} from "@/api/partners";
import { getUserInfo, getInfo } from "@/api/user";
import Pay from "@/pages/payment/pay.vue";

export default {
  name: "partners-apply",
  components: { Pay },
  props: ["id"],
  data() {
    return {
      temps: {},
      customForms: [],
      applyForm: {
        level: "",
        realName: "",
        sex: "1",
        id: "",
        phone: "",
        fullName: "",
        corporateName: "",
        link: "",
        linkPhone: "",
        introduction: "",
        address: "",
        hascard: 0,
        configId: '',
        photo:""
      },
      applyRules: {
        level: [
          { required: true, message: "请选择会员等级", trigger: "change" },
        ],
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        id: [
          { required: true, message: "请输入身份证", trigger: "blur" },
          {
            pattern:
                /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X|x)$/,
            message: "请输入有效的身份证号",
            trigger: "blur",
          },
          {
            required: true,
            len: 18,
            message: "请正确输入身份证号码",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          { required: true, len: 11, message: "请输入正确的联系方式", trigger: "blur", },
          { pattern: /^1[3456789]\d{9}$|^0\d{2,3}-?\d{7,8}$/, message: "请输入正确的联系方式", trigger: "blur", },
        ],
        configId: [
          { required: true, message: "请选择会员类型", trigger: "blur" },
        ],
        fullName: [
          { required: true, message: "请输入单位名称", trigger: "blur" },
        ],
        corporateName: [
          { required: true, message: "请输入法人名称", trigger: "blur" },
        ],
        link: [
          { required: true, message: "请输入联系人名称", trigger: "blur" },
        ],
        linkPhone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          { required: true, len: 11, message: "请输入正确的联系方式", trigger: "blur", },
          { pattern: /^1[3456789]\d{9}$|^0\d{2,3}-?\d{7,8}$/, message: "请输入正确的联系方式", trigger: "blur", },
        ],
        introduction: [
          { required: true, message: "请输入简介", trigger: "blur" },
        ],
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        hascard: [
          { required: true, message: "请选择是否需要实体会员证", trigger: "blur", },
        ],
        photo: [
          { required: true, message: "请上传头像", trigger: "blur", },
        ],
      },
      uType: null,
      uText: null,
      checked: false,

      levelshow: false,
      leveloptions: [],
      configShow: false,
      config: {},
      applyVisiable: true,
      detail: [],
      payId: "",
      itemId: this.$route.query.id || null,
      type: this.$route.query.type,
      offline: false,
      title: "",
      fileList: [],
      orderId: "",
      tenantId: '',
      formData: {
        code: "",
        configId: "",
        dataType: this.$route.query.dataType || 1,//（1会员申请、2会员续费、3旧数据导入）
        memberType: 1,//（1个人，2团体）
        offlineVoucher: "",
        payModel: 1,//（1线上、2线下）
        tenantId: ""
      },
      payVisible: false,
      btnLoading: false,
      isUpdate: false,
      tempsDetals: {},
      configData:[]
    };
  },
  mounted() {

  },
  computed: {

  },
  created() {
    this.tenantId = this.$route.params.id ? this.$route.params.id : ''
    getFullName(this.tenantId).then((res) => {
      this.title = res.fullName;
    });
    if(this.itemId){
      this.getMemberApplyInfo()
    }else {
      this.getUserType()
      this.getUserInfoFun()
    }
  },
  methods: {
    //判断申请会员属于1：个人还:2：团体
    getUserType(){
      let user = JSON.parse(window.localStorage.getItem("user"));
      if (user?.userInfo) {
        if (user.userInfo.tenant_id && user.userInfo.tenant_id != 'NO_TenAntId') {
          this.uType = 2;
        } else {
          this.uType = 1;
        }
      } else {
        getInfo().then((res) => {
          if (res.code === 200) {
            if (res.data.userInfo.tenant_id && res.data.userInfo.tenant_id != 'NO_TenAntId') {
              this.uType = 2;
            } else {
              this.uType = 1;
            }
          }
        });
      }
      this.formData.memberType = this.uType
    },
    //获取用户信息
    getUserInfoFun(){
      getUserInfo().then((res) => {
        if (res.code === 200) {
          let userInfo = res.data;
          this.applyForm.sex = userInfo.gender ? userInfo.gender.toString(): "1"
          this.applyForm.realName = userInfo.realName;
          this.applyForm.id = userInfo.certificatesType === "居民身份证" ? userInfo.certificatesNumber : ''
          this.applyForm.phone = userInfo.mobilePhone;
          this.getMemberConfigGetList()
          this.getMemberBuildFormGetTemplate()
        }
      });
    },
    //获取会员配置信息
    getMemberConfigGetList(){
      memberConfigGetList(this.tenantId,this.uType).then(res=>{
        if(res.code == 200){
          this.configData = res.data;
          // this.configShow = true;
        }
      })
    },
    // 获取动态模板
    getMemberBuildFormGetTemplate() {
      const self = this;
      // self.$store.commit("showLoading");
      memberBuildFormGetTemplate(this.tenantId, this.uType).then((res) => {
        if (res.code == 200) {
          if (res.data) {
            self.temps = res.data;
            self.customForms = JSON.parse(res.data.code);
            if(self.itemId){
              self.getDetail()
            }else {
              self.customForms.map((item) => {
                self.$set(self.applyForm, item.vModel, item.defaultValue || "");
                self.$set(self.applyRules, item.vModel, { required: item.required, message: item.placeholder, trigger: "blur", });
              });
            }
          }
        } else {
          self.$message(res.msg);
        }
        // self.$store.commit("hideLoading");
      });
    },
    //选择会员
    configChange(e){
      this.configData.forEach(item=>{
        if(item.id == e){
          this.config = item
        }
      })
    },
    //上传头像
    handleSuccessPhoto(res, file) {
      this.applyForm.photo = res.data.url
    },
    //上传凭证
    handleSuccess(res, file) {
      this.formData.offlineVoucher = res.data.url
    },
    //根据身份证获取性别
    getGender(id) {
      this.applyForm.sex = this.IdCard(id, 2);
    },
    //成为会员
    submitForm(formName) {
      if(this.formData.payModel == 2 && !this.formData.offlineVoucher){
        return this.$message({ type: "warning", message: '请上传支付凭证！', });
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.formData.code = JSON.stringify(this.applyForm)
          this.formData.tenantId = this.tenantId
          this.formData.configId = this.config.id
          this.formData.offlineVoucher =  this.formData.payModel == 1 ? '' : this.formData.offlineVoucher
          this.btnLoading = true
          memberApplyConfirmApply(this.formData).then(res=>{
            if(res.code == 200){
              if(res.data.isPay){
                this.orderNumber = res.data.orderNo
                this.payVisible = true
                this.$nextTick(() => {
                  this.$refs.Pay.init(res.data.orderNo,1)
                })
              }else {
                this.$router.push({ path: "/result", query: { type: 'success',memberPrice: '0' }, });
              }
              this.btnLoading = false
            }else if(res.code == 4001){
              this.$message({
                type: "error",
                message: res.msg,
                duration: 1500,
                onClose: () => {
                  this.btnLoading = false
                  this.$router.push({ path: "/members", });
                } })
            }else {
              this.btnLoading = false
              this.$message({ type: "error", message: res.msg, })
            }
          }).catch(err=>[
            this.btnLoading = false
          ])
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(){
      this.$router.go(-1)
    },
    //获取会员详情
    getMemberApplyInfo(){
      let that = this
      this.$store.commit("showLoading");
      memberApplyInfo(this.itemId).then(res=>{
        if(res.code == 200){
          that.uType = res.data.memberType
          that.tempsDetals = res.data;
          this.applyForm = JSON.parse(res.data.code);
          that.getMemberConfigGetList()
          that.getMemberBuildFormGetTemplate()
          this.$store.commit("hideLoading");
        }else {
          this.$store.commit("hideLoading");
        }
      }).catch(err=>{
        this.$store.commit("hideLoading");
      })
    },
    getDetail() {
      let detail = [];
      this.customForms.map((item) => {
        detail.push({ name: item.label, type: item.tagIcon, key: item.vModel, value: "", });
      });
      for (let key in this.applyForm) {
        detail.map((item) => {
          if (item.key === key) {
            item.value = this.applyForm[key];
          }
        });
      }
      console.log(detail,9999)
      detail.map((item) => {
        if (item.type === "upload") {
          item.value = item.value.map((img) => {
            return this.loadUrl(img);
          });
          this.fileList.push({
            name:item.name,
            url:item.value
          })
        }
      });
      this.detail = detail;
    },
    //修改保存
    updateForm(){
      let params = {
        applyId:this.itemId,
        code: JSON.stringify(this.applyForm)
      }
      memberApplyConfirmApplyUpdate(params).then(res=>{
        if(res.code == 200){
          if(this.type == 'update'){
            this.$message({ type: "success", message: res.msg, })
            this.$router.go(-1)
          }else if(this.type == 'pay'){
            this.orderNumber = this.tempsDetals.orderNo
            this.payVisible = true
            this.$nextTick(() => {
              this.$refs.Pay.init(this.tempsDetals.orderNo,1)
            })
          }
        }else {
          this.$message({ type: "error", message: res.msg, })
        }
      })
    },
    //动态模板，上传图片或文件
    uploadSuccess(res, file, fileList, vModel) {
      this.fileList = fileList;
      this.applyForm[vModel] = this.fileList.map((item) => {
        return item.response.data.url;
      });
    },
    uploadRemove(file, fileList, vModel) {
      this.fileList = fileList;
      this.applyForm[vModel] = this.fileList.map((item) => {
        return item.response.data.url;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.demo-ruleForm {
  padding: 0 16px;

  ::v-deep .el-input,
  .el-select {
    width: 600px;
  }

  ::v-deep .el-textarea {
    width: 600px;
  }

  ::v-deep .el-textarea__inner {
    font-family: Microsoft YaHei;
  }

  ::v-deep .el-form-item__label {
    padding-right: 30px;
  }

  .noValid {
    ::v-deep .el-form-item__label {
      padding-left: 10.38px;
    }
  }
}

.partners-title {
  margin-top: 20px;
  padding: 0 24px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  height: 46px;

  h2 {
    border-bottom: solid 1px #e5e5e5;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    line-height: 45px;
  }
}

.partners-content {
  padding: 24px 24px 54px;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  min-height: 700px;
  margin-bottom: 20px;
}

.partners-info,
.partners-detail {
  border-top: 1px solid #ececec;
  font-size: 14px;
  color: #555;
  padding: 16px 22px;

  ul:first-child {
    padding-top: 0;
  }

  &.non-border {
    border-top: none;
  }

  &.non-paddingTop {
    padding-top: 0;
    margin-top: -16px;
  }

  .title {
    font-size: 18px;
    color: #333333;
    padding-bottom: 19px;
  }

  .item {
    padding-bottom: 16px;
    display: flex;

    .name {
      flex-shrink: 0;
      //width: 110px;
    }

    .value {
      flex: 1;
    }
  }
}

.partners-info {
  padding: 16px 16px;
}

.partners-detail {
  border: 1px solid #ececec;
}

.partners-btn,
.detail-btn {
  border-top: 1px solid #ececec;
  font-size: 14px;
  color: #555;
  padding: 16px;

  .checkbox-bar {
    .checkbox-content {
      color: #666666;
    }
  }

  .btn-bar {
    padding-top: 20px;
  }
}

.detail-btn {
  text-align: center;
}

.code-dialog {
  ::v-deep {
    .el-dialog__body {
      .qrcode-img {
        width: 300px;
        height: 300px;
        padding: 20px;
      }

      .tip {
        text-align: center;
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 20px;
      }
    }
  }
}
.pay-type-group {
  display: flex;
  flex-direction: column;

  ::v-deep .el-radio__label {
    display: inline-flex;
    align-items: center;
  }

  .icon-weixinzhifu {
    font-size: 20px;
    display: inline-block;

    color: #46bb36;
    padding-right: 5px;

    &.checked {
      //transform: scale(1.5);
      //transition: all 0.5s;
    }
  }

  .icon-zhifubao {
    font-size: 20px;

    display: inline-block;
    color: #02a9f1;
    padding-right: 5px;

    &.checked {
      //transform: scale(1.5);
      //transition: all 0.5s;
    }
  }

  .icon-yinhangqia {
    font-size: 20px;

    display: inline-block;
    color: #ffc11a;
    padding-right: 5px;

    &.checked {
      //transform: scale(1.5);
      //transition: all 0.5s;
    }
  }

  .btn-pay {
    text-align: center;
  }

  .el-radio {
    height: 40px;
    line-height: 40px;
  }

  .offline-radio {
    display: flex;
    align-items: baseline;
  }

  .off-line {
    display: flex;
  }

  ::v-deep.el-button {
    border: 1px solid #999999;
    border-radius: 6px;
    margin-left: 18px;
    padding: 9px;
  }

  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #555;
  }
}
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
